import { register } from 'helpers/api/auth';
import { UserInput } from 'helpers/types';
import { useState } from 'react'; 

export default function useSignup(): any {

    const [user, setUser] = useState();
    const [error, setError] = useState();
    const [loadingRequest, setLoadingRequest] = useState(false);

    const signup = async (registerDatas: UserInput) => {
        setLoadingRequest(true);
        try {
            const result = await register(registerDatas);
            setUser(result.data);

            // Save the user in the localstorage for account validation purpose
            localStorage.setItem("authUser", JSON.stringify(result.data));
        } catch(error: any) {
            setError(error?.response?.data.error_message);
        }
        setLoadingRequest(false);
    };

    return [user, error, signup, loadingRequest];
}
