import { logout } from "helpers/api/auth";

export default function useLogout() {

    const logoutUser = async () => {
        try {
            const result = await logout();

            localStorage.removeItem("authUser");
            localStorage.removeItem("token");
        } catch(error: any) {}
    };

    return [logoutUser];
}
