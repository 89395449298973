import { forgotPassword } from 'helpers/api/auth';
import { useState } from 'react';

export default function useForgotPassword(): [string, string, boolean, boolean, ({ email }: { email: string }) => void] {
    const [resetPasswordSuccess, setResetPasswordSuccess] = useState('');
    const [error, setError] = useState('');
    const [passwordReset, setPasswordReset] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const forgotUserPassword = async ({ email }: { email: string }) => {
        setLoading(true);
        try {
            const result = await forgotPassword({ email });
            localStorage.setItem('resetMail', email);
            setResetPasswordSuccess(result.success_message);
            setPasswordReset(true);
        } catch(err: any) {
            setError(err.response.data.error_message);
        }
        setLoading(false)
    };

    return [resetPasswordSuccess, error, passwordReset, loading, forgotUserPassword];
}
