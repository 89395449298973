import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// components
import PrivateRoute from './PrivateRoute';

// lazy load all the views
// public
// const Home = React.lazy(() => import('../pages/public/Home'));
const NotFound = React.lazy(() => import('../pages/public/NotFound'));
const PublicServices = React.lazy(() => import('../pages/public/Services'));
const Account = React.lazy(() => import('../pages/public/Account'));
const PublicDetailsService = React.lazy(() => import('../pages/public/PublicDetailsService'));
const PublicReservation = React.lazy(() => import('../pages/public/PublicReservation'));
const InvitationConfirmation = React.lazy(() => import('../pages/public/InvitationConfirmation'));
const Checkout = React.lazy(() => import('../pages/public/Checkout'));
const PublicDetailsReservation = React.lazy(() => import('../pages/public/PublicDetailsReservation'));
const PrivacyPolicy = React.lazy(() => import('../pages/public/PrivacyPolicy'));
const Contact = React.lazy(() => import('../pages/public/Contacts'));

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const SignUp = React.lazy(() => import('../pages/auth/SignUp'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const ResetPassword = React.lazy(() => import('../pages/auth/ResetPassword'));
const ChangePassword = React.lazy(() => import('../pages/auth/ChangePassword'));

// admin
const Services = React.lazy(() => import('../pages/admin/Services'));
const Reservations = React.lazy(() => import('../pages/admin/Reservations'));
const Promotions = React.lazy(() => import('../pages/admin/Promotions'));
const Invitations = React.lazy(() => import('../pages/admin/Invitations'));
const DetailsReservations = React.lazy(() => import('../pages/admin/Reservations/DetailsReservation'));
const Customers = React.lazy(() => import('../pages/admin/Customers'));
const DetailsCustomers = React.lazy(() => import('../pages/admin/Customers/DetailsCustomers'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            // root & public routes
            path: '/',
            children: [
                { path: '', element: <LoadComponent component={PublicServices} /> },
                { path: 'services', element: <LoadComponent component={PublicServices} /> },
                { path: 'contact-us', element: <LoadComponent component={Contact} /> },
                { path: 'services/:id', element: <LoadComponent component={PublicDetailsService} /> },
                {
                    path: 'invitation-confirmation/:id',
                    element: <LoadComponent component={InvitationConfirmation} />,
                },
                { path: 'privacy', element: <LoadComponent component={PrivacyPolicy} /> },
            ],
        },
        {
            // auth routes
            path: '/',
            children: [
                {
                    path: 'auth',
                    children: [
                        { path: 'signup', element: <LoadComponent component={SignUp} /> },
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                        { path: 'reset-password', element: <LoadComponent component={ResetPassword} /> },
                        { path: 'change-password', element: <LoadComponent component={ChangePassword} /> },
                    ],
                },
            ],
        },
        {
            // protected user routes
            path: '/',
            element: <PrivateRoute roles={'user'} />,
            children: [
                {
                    path: '',
                    children: [
                        { path: 'my-account', element: <LoadComponent component={Account} /> },
                        { path: 'services/:id/reserve', element: <LoadComponent component={PublicReservation} /> },
                        { path: 'services/:id/checkout', element: <LoadComponent component={Checkout} /> },
                        {
                            path: 'my-reservations/:id',
                            element: <LoadComponent component={PublicDetailsReservation} />,
                        },
                    ],
                },
            ],
        },
        {
            // protected admin routes
            path: '/',
            element: <PrivateRoute roles={'admin'} />,
            children: [
                {
                    path: 'admin',
                    children: [
                        // { path: 'dashboard', element: <LoadComponent component={Dashboard} /> },
                        { path: 'services', element: <LoadComponent component={Services} /> },
                        { path: 'services/details', element: <LoadComponent component={PublicDetailsService} /> },
                        { path: 'reservations', element: <LoadComponent component={Reservations} /> },
                        { path: 'reservations/:id', element: <LoadComponent component={DetailsReservations} /> },
                        { path: 'promotions', element: <LoadComponent component={Promotions} /> },
                        { path: 'invitations', element: <LoadComponent component={Invitations} /> },
                        { path: 'customers', element: <LoadComponent component={Customers} /> },
                        { path: 'customers/:id', element: <LoadComponent component={DetailsCustomers} /> },
                    ],
                },
            ],
        },
        {
            path: '*',
            element: <LoadComponent component={NotFound} />,
        },
    ]);
};

export default AllRoutes;
