import { del, post } from "./apiCore";

// Gets the logged in user data from local session
export const getLoggedInUserInfos = () => {
  const user = localStorage.getItem("authUser");

  if (user) return JSON.parse(user);
  return null;
};

export const getLoggedInUserToken = () => {
  const token = localStorage.getItem("token");
  if (token) return JSON.parse(token);
  return null;
};

// Get request authorization config
export const getAuthConfig = () => {
  const token = getLoggedInUserToken();
  return {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
};

export const getFileAuthConfig = () => {
  const token = getLoggedInUserToken();

  return {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  };
};

// Is user logged in
export const isUserAuthenticated = () => {
  return getLoggedInUserToken() !== null;
};

// Checks if user profile is completed
export const isProfileCompleted = () => {
  const profile = getLoggedInUserInfos();
  delete (profile.picture);
  let isCompleted = true;

  if (!profile.is_verified) return false;

  Object.values(profile).forEach((val, key) => {
    if (val == null || val == undefined) {
      isCompleted = false;
    }
  })

  return isCompleted;
};

// Checks if user account is verified
export const isAccountVerified = () => {
  const profile = getLoggedInUserInfos();
  if (!profile || !profile.is_verified) return false;
  else return true;
};

// Remove user datas from localstorage
export const clearStoredAuthDatas = () => {
  localStorage.removeItem("authUser");
  localStorage.removeItem("token");
};

// Auth Methods
export const register = (data: any) => post("/auth/register", data);

export const login = (data: any) => post("/auth/login", data);

export const logout = () => post("/auth/logout", null, getAuthConfig());

export const forgotPassword = (data: any) => post("auth/password-forgotten", data);

export const resetPassword = (data: any) => post("auth/reset-password", data);

export const updateProfile = (data: any) =>
  post(
    "/users/update-profile",
    { ...data, _method: "PUT" },
    getFileAuthConfig()
  );

export const changePassword = (data: any) =>
  post("auth/change-password", data, getAuthConfig());

export const deleteAccount = (data: any) =>
  del("" + "/" + data, getAuthConfig());

export const validateAccount = (data: any) => post("/auth/verify-email", data);

export const requestValidationCode = (data: any) => post("/auth/request-code", data);

export const identifyCustomer = (data: any) => post("/auth/customer-identification", data);