import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// hooks
import { useUser } from '../hooks/auth';
import { isUserAuthenticated } from 'helpers/api/auth';

type PrivateRouteProps = {
    roles?: string;
};

const loading = () => <div className=""></div>;

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ roles, ...rest }: PrivateRouteProps) => {
    const [loggedInUser] = useUser();

    /**
     * not logged in so redirect to login page with the return url
     */
    if (!isUserAuthenticated()) {
        return <Navigate to={{ pathname: '/auth/login' }} replace />;
    }

    // Checks if route is restricted by admin role
    if (roles && roles == "admin" && !loggedInUser?.is_admin) {
        // role not authorised so redirect to login page
        return <Navigate to={{ pathname: '/auth/login' }} replace />;
    }
    
    return (
        <Suspense fallback={loading()}>
            <Outlet />
        </Suspense>
    );
};

export default PrivateRoute;
