// helpers
import { login } from 'helpers/api/auth';
import { useState } from 'react'; 

export default function useLogin(): any {
    const [user, setUser] = useState();
    const [error, setError] = useState();
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false);

    const loginUser = async ({ email, password }: { email: string; password: string }) => {
        setLoadingRequest(true);
        try {
            const result = await login({ email, password });
            localStorage.setItem("authUser", JSON.stringify(result.data.user));
            localStorage.setItem("token", JSON.stringify(result.data.token));
            setUser(result.data.user);
        } catch(error: any) {
            setError(error!.response.data.error_message);
        }
        setLoadingRequest(false);
    };

    return [user, error, loadingRequest, loginUser];
}
