import axios, { AxiosRequestHeaders } from 'axios';

//apply base url for axios
const API_URL = {
    locale: process.env.REACT_APP_LOCAL_API_URL,
    live: process.env.REACT_APP_LIVE_API_URL,
    remote: process.env.REACT_APP_REMOTE_API_URL,
};

const axiosApi = axios.create({
    baseURL: API_URL.live,
});

axiosApi.defaults.headers.common['Accept-Language'] = 'fr-FR';
axiosApi.defaults.headers.common['Content-Type'] = 'application/json';
axiosApi.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// axiosApi.interceptors.request.use(
//     (request) => {
//       request.headers = {
//         'Access-Control-Allow-Origin': '*',
//         'X-Requested-With': 'XMLHttpRequest',
//         Accept: 'application/json',
//         Authorization: `Bearer ${
//           localStorage.getItem('accessToken') ?? ''
//         }`,
//       } as unknown as AxiosRequestHeaders;

//       return request;
//     },
//     (error) => Promise.reject(error)
//   );

axiosApi.interceptors.response.use(
    (response) => response,
    (error) => {
        if (
            error &&
            error.response &&
            401 === error.response.status &&
            !(window.location.href.indexOf('/auth/login') > -1)
        ) {
            localStorage.clear();
            window.location.replace('/auth/login');
        }
        return Promise.reject(error);
    }
);

export function makeUrl(params: object) {
    let url = '';
    for (const [key, value] of Object.entries(params)) {
        url += `${key}=${value === undefined ? '' : encodeURIComponent(value)}&`;
    }
    url = url.slice(0, -1);

    return url;
}

export async function get(url: string, config: object = {}) {
    try {
        const response = await axiosApi.get(url, config);
        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export async function post(url: string, data: any, config: object = {}) {
    return axiosApi.post(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function put(url: string, data: any, config: object = {}) {
    return axiosApi.put(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function del(url: string, config: object = {}) {
    return await axiosApi.delete(url, { ...config }).then((response) => response.data);
}
